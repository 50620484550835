import React, { useContext } from "react";
import {Card, Divider, Space, Tooltip, Typography} from "antd";
import {
  OrganizationModalContext
} from "../../../../../../contexts/shared";
import { EllipsisOutlined } from "@ant-design/icons";
import { CoverImageFit, TagList } from "../../../../../shared";
import { COLORS_TAGS } from "../../../../../../helpers/select-options";

export function OrganizationItem({ organization }) {
  const { openModal } = useContext(OrganizationModalContext);

  return (
    <Card
      hoverable
      className="clickable"
      onClick={() => openModal(organization?.id)}
      cover={
        <CoverImageFit
          text={organization.name}
          style={{objectFit: "contain"}}
          url={organization.logo} />
      }>
      <Card.Meta
        title={
          <>
            <Space className="OrganizationIndex__space-title">
              <Tooltip title={organization?.name} placement={"bottom"}>
                <Typography.Title level={5}>
                  {
                    organization?.name.length > 17
                      ? `${organization?.name.substring(0,17)}...`
                      : organization?.name
                  }
                </Typography.Title>
              </Tooltip>
              <Typography.Text type="secondary">
                {organization?.private ? "Privado" : "Público"}
              </Typography.Text>
            </Space>
            <Divider />
          </>
        }
        description={
          <Space
            className="OrganizationIndex__space-body"
            direction="vertical">
            <Typography.Text strong>
              Etapas en la que apoya
            </Typography.Text>
            <TagList
              className="tags"
              maxCount={2}
              colors={COLORS_TAGS}
              mapColors={true}
              tags={organization?.stages} />
            <Typography.Text strong>
              Servicios
            </Typography.Text>
            <Space
              className="OrganizationIndex__space-footer"
              direction="horizontal">
              <Typography.Text type="secondary">
                {organization?.services.slice(0, 2).join(" | ")}
              </Typography.Text>
              <EllipsisOutlined />
            </Space>
          </Space>
        } />
    </Card>
  );
}
